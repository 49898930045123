<template>
  <div>
    <div class="row">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          shadow-card
          p-5
        "
      >
        <h1>Edit User</h1>
        <alert class="mt-5" v-if="loading" />
        <form @submit.prevent="saveUser" validate v-if="!loading">
          <input-control
            type="horizontal"
            v-model="user.first"
            required
            labelClass="col-md-3 col-lg-2"
            >First Name</input-control
          >
          <input-control
            type="horizontal"
            v-model="user.last"
            required
            labelClass="col-md-3 col-lg-2"
            >Last Name</input-control
          >

          <select-control
            v-model="user.gender"
            :options="genderOptions"
            labelClass="col-md-3 col-lg-2 col-12"
            >Gender</select-control
          >
          <input-control
            type="horizontal"
            v-model="user.email"
            control="email"
            required
            labelClass="col-md-3 col-lg-2"
            >Email</input-control
          >
          <input-control
            v-model="user.phone_number"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >Phone Number</input-control
          >
          <input-control
            type="horizontal"
            v-model="user.username"
            required
            labelClass="col-md-3 col-lg-2"
            >User Name</input-control
          >
          <input-control
            type="horizontal"
            v-model="user.password"
            control="password"
            labelClass="col-md-3 col-lg-2"
            >Password</input-control
          >

          <div class="form-group row">
            <label class="col-md-3 col-lg-2" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="user.birth_date"
                placeholder=""
                :week-start="7"
                zone="UTC"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <input-control
            type="horizontal"
            v-model="user.hstNumber"
            labelClass="col-md-3 col-lg-2"
            >HST Number</input-control
          >

          <select-control
            v-if="loggedUser && loggedUser.isAdmin"
            v-model="user.status"
            :options="statuses"
            required
            labelClass="col-md-3 col-lg-2 col-12"
            >Status</select-control
          >

          <div class="form-group row">
            <div class="col-auto col-md-3 col-lg-2 is-manager">
              <input
                type="checkbox"
                :disabled="user.id == loggedUser.id"
                id="input-control-is-manager"
                v-model="user.is_managing_admin"
                class="form-control ml-md-3 pointer-cursor"
              />
            </div>
            <label
              for="input-control-is-manager"
              class="
                col-auto col-form-label
                pointer-cursor
                d-flex
                align-items-center
              "
              >Managing Admin</label
            >
          </div>
          <save classes="mt-3 save-button btn btn-theme" :saving="saving"
            >Save</save
          >
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { Datetime } from "vue-datetime";

export default {
  name: "CreateUser",
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      loading: true,
      genderOptions: [
        { value: "0", label: "Other" },
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
      ],
      statuses: [
        { value: "ACTIVE", label: "Active" },
        { value: "INACTIVE", label: "Inactive" },
      ],
      saving: false,
      user: {
        first: null,
        last: null,
        email: null,
        password: null,
        phone_number: null,
        is_managing_admin: false,
        hstNumber: null,
      },
    };
  },
  async mounted() {
    if (this.$route.params.id != undefined) {
      const res = await this.$http.get(`users/${this.$route.params.id}`);
      this.user = res.data.data;
      this.loading = false;
      if (this.user.isManagingAdmin) {
        this.user.is_managing_admin = true;
      }
      if ((this.user.phones || []).length) {
        this.user.phone_number =
          (
            this.user.phones.filter(
              (phone) => !phone.isDeleting && phone.number
            )[0] || {}
          ).number || "";
      }
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      loggedUser: (state) => state.auth.user,
    }),
  },
  methods: {
    saveUser: function () {
      let vm = this;
      let dataToSave = {
        id: this.user.id,
        first: this.user.first,
        last: this.user.last,
        gender: this.user.gender,
        email: this.user.email,
        username: this.user.username || this.user.email,
        phone_number: this.user.phone_number,
        password: this.user.password,
        is_managing_admin: this.user.is_managing_admin,
        status: this.user.status,
        birth_date: this.user.birth_date || null,
        hstNumber: this.user.hstNumber,
      };
      let directionText = null;
      if (
        this.user.isManagingAdmin !== (this.user.is_managing_admin || false)
      ) {
        directionText = this.user.isManagingAdmin
          ? "Managing Admin to Admin"
          : "Admin to Managing Admin";
      }
      if (directionText) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: `You are trying to change the type for this user from ${directionText}. Thus, the group of the converted user will move to the default group`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, change",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              vm.saving = true;
              vm.$http
                .put("users/" + this.user.id, dataToSave)
                .then((res) => {
                  if (res) {
                    Swal.fire({
                      title: "Updated",
                      text: "User Updated Successfully",
                      icon: "success",
                    });
                    vm.saving = false;

                    vm.$router.push("/admin/users");
                  }
                })
                .catch((err) => {
                  if (!err.accessDenied) {
                    Swal.fire({
                      title: "Error",
                      text: err.data.message || "Something went wrong...",
                      icon: "error",
                    });
                  }
                  vm.saving = false;
                });
            }
          });
      } else {
        vm.saving = true;
        this.$http
          .put("users/" + this.user.id, dataToSave)
          .then((res) => {
            if (res) {
              Swal.fire({
                title: "Updated",
                text: "User Updated Successfully",
                icon: "success",
              });
              vm.saving = false;

              vm.$router.push("/admin/users");
            }
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err.data.message || "Something went wrong...",
                icon: "error",
              });
            }
            vm.saving = false;
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.is-manager input {
  min-width: 25px;
  width: 25px;
}
</style>
